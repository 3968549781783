import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Container from '../components/Container'
import PageBody from '../components/PageBody'
import TagList from '../components/TagList'
import PostLinks from '../components/PostLinks'
import PostDetails from '../components/PostDetails'
import DisqusWrapper from '../components/Disqus'
import SEO from '../components/SEO'
import Disqus from 'gatsby-plugin-disqus'
import {
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
  LineShareButton,
  LineIcon,
  EmailShareButton,
  EmailIcon,
  FacebookShareCount,
} from 'react-share'
import ShareWrapper from '../components/ShareWrapper'
require(`katex/dist/katex.min.css`)

const PostTemplate = ({ data, pageContext }) => {
  const {
    title,
    slug,
    heroImage,
    body,
    publishDate,
    tags,
  } = data.contentfulPost
  const postNode = data.contentfulPost
  const previous = pageContext.prev
  const next = pageContext.next
  const url = `${config.siteUrl}/${slug}`
  return (
    <Layout>
      <Helmet>
        <title>{`${title} - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO pagePath={slug} postNode={postNode} postSEO />

      <Hero title={title} image={heroImage} height={'50vh'} />

      <Container>
        {tags && <TagList tags={tags} />}
        <PostDetails
          date={publishDate}
          timeToRead={body.childMarkdownRemark.timeToRead}
        />
        <ShareWrapper>
          <TwitterShareButton title={title} via="@kenjikun__" url={url}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          <FacebookShareButton quote={title} url={url}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <FacebookShareCount url={url} />
          <LineShareButton title={title} url={url}>
            <LineIcon size={32} round />
          </LineShareButton>
          <EmailShareButton subject={title} url={url}>
            <EmailIcon size={32} round />
          </EmailShareButton>
        </ShareWrapper>
        <PageBody body={body} />
        <ShareWrapper>
          <TwitterShareButton title={title} via="@kenjikun__" url={url}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          <FacebookShareButton quote={title} url={url}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <FacebookShareCount url={url} />
          <LineShareButton title={title} url={url}>
            <LineIcon size={32} round />
          </LineShareButton>
          <EmailShareButton subject={title} url={url}>
            <EmailIcon size={32} round />
          </EmailShareButton>
        </ShareWrapper>

        <DisqusWrapper>
          <Disqus identifier={postNode.id} title={title} url={url} />
        </DisqusWrapper>
      </Container>
      <PostLinks previous={previous} next={next} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      title
      slug
      metaDescription {
        internal {
          content
        }
      }
      publishDate(formatString: "MMMM DD, YYYY")
      publishDateISO: publishDate(formatString: "YYYY-MM-DD")
      tags {
        title
        id
        slug
      }
      heroImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        ogimg: resize(width: 1800) {
          src
          width
          height
        }
      }
      body {
        childMarkdownRemark {
          timeToRead
          html
          excerpt(pruneLength: 320)
        }
      }
    }
  }
`

export default PostTemplate
